import axios from 'axios';
import { toastError } from '../../helpers/alert';
import { searchArray } from '../../helpers/arr';
import { buildUrl } from '../../helpers/utils';
import { urls } from './_crm.configs';

export const getLead = async (id) => {
    try {
        const { data } = await axios.get(buildUrl(urls.lead_details, { id }));
        data.person_entity = searchArray(data.applicants, 'is_authorized_signatory', true);
        data.business_entity = searchArray(data.applicants, 'applicant_type', 'business');
        return data;
    } catch (error) {
        toastError('An error occurred while fetching lead details');
    }
};

export const verifyLead = async (payload) => {
    const { data } = await axios.post(urls.onboarding_leads_verify, payload);
    return data;
};

export const getVerificationMatrix = async (id, force = false) => {
    const { data } = await axios.put(buildUrl(urls.vendors_matrix, { id, force }));
    return data?.matrix_data;
};

export const getLeadPrograms = async (id) => {
    const { data } = await axios.get(buildUrl(urls.lead_programs, { id }));
    return data;
};

export const getLeadProgram = async (id, program_id) => {
    const { data } = await axios.get(
        buildUrl(urls.lead_program, {
            id,
            program_id,
        })
    );
    return data;
};

export const getDashboardSummary = async () => {
    const { data } = await axios.get(urls.dashboard_summary);
    return data;
};

export const getLeadServiceAccounts = async (id) => {
    const { data } = await axios.get(buildUrl(urls.lead_service_accounts, { id }));
    return data;
};

export const getLeadProgramAccounts = async (id, program_id) => {
    const { data } = await axios.get(buildUrl(urls.lead_program_accounts, { id, program_id }));
    return data;
};

export const listLeads = async (params) => {
    const { data } = await axios.get(buildUrl(urls.leads, params));
    return data;
};

export const listDocuments = async (type, id, params = {}) => {
    const { data } = await axios.get(
        buildUrl(urls.documents, {
            related_model_name: type,
            related_model_id: id,
            'no-pagination': true,
            ...params,
        })
    );
    return data;
};

export const getDocument = async (type, id, name) => {
    const { data } = await axios.get(
        buildUrl(urls.documents, {
            related_model_name: type,
            related_model_id: id,
            name: name,
        })
    );
    return data?.results?.[0];
};

export const listAddresses = async (applicant_id) => {
    const { data } = await axios.get(
        buildUrl(urls.addresses, { applicant_id, 'no-pagination': true })
    );
    return data;
};

export const getCurrentAddress = async (applicant_id) => {
    const { data } = await axios.get(
        buildUrl(urls.addresses, { applicant_id, 'no-pagination': true })
    );
    return data[0] || {};
};

export const runRouting = async (id) => {
    const { data } = await axios.put(buildUrl(urls.run_routing, { id }));
    return data;
};

export const runBre = async (id, program_id) => {
    const { data } = await axios.put(buildUrl(urls.lead_run_bre, { id, program_id }));
    return data;
};

export const createComment = async (payload) => {
    const { data } = await axios.post(urls.comments, payload);
    return data;
};

export const listBanks = async (applicant_id, filter = null) => {
    const { data } = await axios.get(buildUrl(urls.banks, { applicant_id }), {
        params: { ...filter, 'no-pagination': true },
    });
    return data;
};

export const getBank = async (id) => {
    const { data } = await axios.get(buildUrl(urls.bank_details, { id }));
    return data;
};

export const updateBank = async (id, payload) => {
    const { data } = await axios.patch(buildUrl(urls.bank_details, { id }), payload);
    return data;
};

export const gstFilingData = async (gstin) => {
    const { data } = await axios.post(urls.vendors_gst_filing, { gstin });
    return data;
};

export const pingBureau = async (applicant_id) => {
    const { data } = await axios.put(buildUrl(urls.applicants_ping_bureau, { id: applicant_id }));
    return data;
};

export const getVendorData = async (params) => {
    const { data } = await axios.get(urls.vendors, {
        params,
    });
    return data;
};

export const listVendorData = async (related_model_id, related_model_name, vendor_key) => {
    const { data } = await axios.get(
        buildUrl(urls.vendors, {
            related_model_id,
            related_model_name,
            vendor_key,
        })
    );

    return data;
};

export const listGstDirectors = async (lead_id) => {
    const { data } = await axios.get(buildUrl(urls.onboarding_gst_directors, { lead_id }));
    return data;
};

export const createApplicant = async (payload) => {
    const { data } = await axios.post(urls.applicants, payload);
    return data;
};

export const applicantGstAnalytics = async (id) => {
    const { data } = await axios.get(buildUrl(urls.applicant_gst_analytics, { id }));
    return data;
};

export const applicantItr = async (id) => {
    const { data } = await axios.get(buildUrl(urls.applicant_itr, { id }));
    return data;
};

export const vendorGstAnalytics = async (id) => {
    const { data } = await axios.get(
        buildUrl(urls.gst_analytics_data, {
            id,
        })
    );
    return data;
};

export const createGstAnalyticsRequest = async (id, payload) => {
    const { data } = await axios.post(buildUrl(urls.gst_analytics_request, { id }), payload);
    return data;
};

export const submitGstAnalyticsOtp = async (id, payload) => {
    const { data } = await axios.post(buildUrl(urls.gst_analytics_otp_submit, { id }), payload);
    return data;
};

export const fetchGstAnalyticsReport = async (id, payload) => {
    const { data } = await axios.post(buildUrl(urls.gst_analytics_report_fetch, { id }), payload);
    return data;
};