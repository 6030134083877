import clsx from 'clsx';
import { FC, useEffect, useRef, useState } from 'react';
import { SearchComponent } from '../../../assets/ts/components';
import { KTIcon, toAbsoluteUrl } from '../../../helpers';
import Svg from '../../../../app/components/atoms/Svg';
import { listLeads } from '../../../../app/modules/Crm/_crm.service';
import { Link } from 'react-router-dom';
import { getInitial } from '../../../../app/helpers/utils';
import { listTopups } from '../../../../app/modules/Disbursal/_disbursal.service';
import { transform } from '../../../../app/helpers/transform';
import { beautify } from '../../../../app/helpers/str';
import { listCompanies, listPrograms } from '../../../../app/modules/Unr/_unr.service';
import { listUsers } from '../../../../app/modules/Identity/_identity.service';
import { setUser } from '@sentry/react';

const Search = ({ className = '', mobileToggleBtnClass = '' }) => {
    const [menuState, setMenuState] = useState('main');
    const element = useRef(null);
    const searchBox = useRef(null);
    const wrapperElement = useRef(null);
    const resultsElement = useRef(null);
    const suggestionsElement = useRef(null);
    const emptyElement = useRef(null);

    const [leads, setLeads] = useState([]);
    const [topups, setTopups] = useState([]);
    const [programs, setPrograms] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [users, setUsers] = useState([]);

    const processs = (search) => {
        setTimeout(async function () {
            const leadsResponse = await initLeads(search.inputElement.value);
            const topupsResponse = await initTopups(search.inputElement.value);
            const programsResponse = await initPrograms(search.inputElement.value);
            const companiesResponse = await initCompanies(search.inputElement.value);
            const usersResponse = await initUsers(search.inputElement.value);

            const count =
                leadsResponse?.length +
                topupsResponse?.length +
                programsResponse?.length +
                companiesResponse.length +
                usersResponse.length;

            suggestionsElement.current?.classList.add('d-none');

            if (count === 0) {
                resultsElement.current?.classList.add('d-none');
                emptyElement.current?.classList.remove('d-none');
            } else {
                resultsElement.current?.classList.remove('d-none');
                emptyElement.current?.classList.add('d-none');
            }

            search.complete();
        }, 1500);
    };

    const initLeads = async (search) => {
        const response = await listLeads({
            search,
            expand: 'program',
        });
        setLeads(response.results);
        return response.results;
    };

    const initTopups = async (search) => {
        const response = await listTopups({
            search,
            expand: 'program',
        });
        setTopups(response.results);
        return response.results;
    };

    const initPrograms = async (search) => {
        const response = await listPrograms({
            search,
            expand: 'program',
        });
        setPrograms(response.results);
        return response.results;
    };

    const initCompanies = async (search) => {
        const response = await listCompanies({
            search,
            exclude_me: true,
        });
        setCompanies(response.results);
        return response.results;
    };

    const initUsers = async (search) => {
        const response = await listUsers({
            search,
            exclude_me: true,
        });
        setUsers(response.results);
        return response.results;
    };

    const clear = () => {
        // Show recently viewed
        suggestionsElement.current?.classList.remove('d-none');
        // Hide results
        resultsElement.current?.classList.add('d-none');
        // Hide empty message
        emptyElement.current?.classList.add('d-none');
    };

    useEffect(() => {
        // Initialize search handler
        const searchObject = SearchComponent.createInsance('#kt_header_search');

        // Search handler
        searchObject?.on('kt.search.process', processs);

        // Clear handler
        searchObject?.on('kt.search.clear', clear);

        const handleKeyDown = (event) => {
            // Check if Cmd (or Ctrl on Windows) and K are pressed simultaneously
            if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
                event.preventDefault(); // Prevent default behavior of Cmd+K (e.g., opening browser's search bar)
                if (searchBox.current) {
                    searchBox.current.focus(); // Focus on the textbox
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    return (
        <div
            id='kt_header_search'
            className={clsx('d-flex align-items-center', className)}
            data-kt-search-keypress='true'
            data-kt-search-min-length='2'
            data-kt-search-enter='enter'
            data-kt-search-layout='menu'
            data-kt-search-responsive='lg'
            data-kt-menu-trigger='auto'
            data-kt-menu-permanent='true'
            data-kt-menu-placement='bottom-end'
            data-kt-search='true'
            ref={element}>
            <div
                className={clsx('d-flex d-lg-none align-items-center')}
                data-kt-search-element='toggle'>
                <div className={mobileToggleBtnClass}>
                    <Svg
                        icon='magnifier'
                        className={`svg-icon-2x`}
                    />
                </div>
            </div>

            <form
                action='/dashboard/search'
                data-kt-search-element='form'
                className='d-none align-items-center d-lg-flex w-100 mb-5 mb-lg-0 position-relative'
                autoComplete='off'>
                <Svg
                    icon='magnifier'
                    className={`svg-icon-2x fs-2 text-gray-600 position-absolute top-50 translate-middle-y ms-4`}
                />

                <input
                    ref={searchBox}
                    type='text'
                    className='form-control bg-transparent ps-13 fs-7 h-40px border-gray-700 text-white'
                    name='q'
                    placeholder='Search (cmd + k)'
                    data-kt-search-element='input'
                />

                <span
                    className='position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-5'
                    data-kt-search-element='spinner'>
                    <span className='spinner-border h-15px w-15px align-middle text-gray-500' />
                </span>

                <span
                    className='btn btn-flush btn-active-color-primary position-absolute top-50 end-0 translate-middle-y lh-0 d-none me-4'
                    data-kt-search-element='clear'>
                    <Svg
                        icon='cross'
                        className='fs-2 text-lg-1 me-0'
                    />
                </span>
            </form>

            <div
                data-kt-search-element='content'
                data-kt-menu='true'
                className='menu menu-sub menu-sub-dropdown w-300px w-md-350px py-7 px-7 overflow-hidden'>
                <div
                    className={`${menuState === 'main' ? '' : 'd-none'}`}
                    ref={wrapperElement}
                    data-kt-search-element='wrapper'>
                    <div
                        ref={resultsElement}
                        data-kt-search-element='results'
                        className='d-none'>
                        <div className='scroll-y mh-100'>
                            {leads.length > 0 && (
                                <>
                                    <h3
                                        className='fs-5 text-muted m-0 pb-5'
                                        data-kt-search-element='category-title'>
                                        Borrowers
                                    </h3>
                                    {leads?.map((lead) => {
                                        return (
                                            <Link
                                                to={`/crm/borrowers/${lead.id}/`}
                                                key={lead.id}
                                                className='d-flex text-gray-900 text-hover-primary align-items-center mb-5'>
                                                <div className='symbol symbol-40px me-2'>
                                                    <span className='symbol-label fs-3 bg-light-danger text-danger'>
                                                        {getInitial(
                                                            lead?.primary_applicant?.name
                                                        ).toUpperCase()}
                                                    </span>
                                                </div>
                                                <div className='d-flex flex-column justify-content-start fw-bold'>
                                                    <span className='fs-6 fw-bold'>
                                                        {lead?.primary_applicant?.name} (
                                                        {lead?.ref_id})
                                                    </span>
                                                    <span className='fs-7 fw-bold text-muted'>
                                                        {lead?.program?.program?.name}
                                                    </span>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </>
                            )}
                            {topups.length > 0 && (
                                <>
                                    <h3
                                        className='fs-5 text-muted m-0 pb-5'
                                        data-kt-search-element='category-title'>
                                        Topups
                                    </h3>
                                    {topups?.map((topup) => {
                                        return (
                                            <Link
                                                to={`/disbursal/topups/${topup.id}/`}
                                                key={topup.id}
                                                className='d-flex text-gray-900 text-hover-primary align-items-center mb-5'>
                                                <div className='symbol symbol-40px me-2'>
                                                    <span className='symbol-label fs-3 bg-light-danger text-danger'>
                                                        {getInitial(topup?.ref_id).toUpperCase()}
                                                    </span>
                                                </div>
                                                <div className='d-flex flex-column justify-content-start fw-bold'>
                                                    <span className='fs-6 fw-bold'>
                                                        {topup?.ref_id}
                                                    </span>
                                                    <span
                                                        className={`fs-7 fw-bold text-${transform(
                                                            'class',
                                                            topup?.status
                                                        )}`}>
                                                        {beautify(topup?.status)}
                                                    </span>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </>
                            )}
                            {programs.length > 0 && (
                                <>
                                    <h3
                                        className='fs-5 text-muted m-0 pb-5'
                                        data-kt-search-element='category-title'>
                                        Programs
                                    </h3>
                                    {programs?.map((program) => {
                                        return (
                                            <Link
                                                to={`/admin/programs/${program.id}/`}
                                                key={program.id}
                                                className='d-flex text-gray-900 text-hover-primary align-items-center mb-5'>
                                                <div className='symbol symbol-40px me-2'>
                                                    <span className='symbol-label fs-3 bg-light-danger text-danger'>
                                                        {getInitial(program?.name).toUpperCase()}
                                                    </span>
                                                </div>
                                                <div className='d-flex flex-column justify-content-start fw-bold'>
                                                    <span className='fs-6 fw-bold'>
                                                        {program?.name}
                                                    </span>
                                                    <span className={`fs-7 fw-bold text-muted`}>
                                                        {program?.ref_id}
                                                    </span>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </>
                            )}
                            {companies.length > 0 && (
                                <>
                                    <h3
                                        className='fs-5 text-muted m-0 pb-5'
                                        data-kt-search-element='category-title'>
                                        Companies
                                    </h3>
                                    {companies?.map((company) => {
                                        return (
                                            <Link
                                                to={`/admin/companies/${company.id}/`}
                                                key={company.id}
                                                className='d-flex text-gray-900 text-hover-primary align-items-center mb-5'>
                                                <div className='symbol symbol-40px me-2'>
                                                    <span className='symbol-label fs-3 bg-light-danger text-danger'>
                                                        {getInitial(company?.name).toUpperCase()}
                                                    </span>
                                                </div>
                                                <div className='d-flex flex-column justify-content-start fw-bold'>
                                                    <span className='fs-6 fw-bold'>
                                                        {company?.name}
                                                    </span>
                                                    <span className={`fs-7 fw-bold text-muted`}>
                                                        {company?.type}
                                                    </span>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </>
                            )}
                            {users.length > 0 && (
                                <>
                                    <h3
                                        className='fs-5 text-muted m-0 pb-5'
                                        data-kt-search-element='category-title'>
                                        Users
                                    </h3>
                                    {users?.map((user) => {
                                        return (
                                            <Link
                                                to={`/admin/users/${user.id}/`}
                                                key={user.id}
                                                className='d-flex text-gray-900 text-hover-primary align-items-center mb-5'>
                                                <div className='symbol symbol-40px me-2'>
                                                    <span className='symbol-label fs-3 bg-light-danger text-danger'>
                                                        {getInitial(user?.name).toUpperCase()}
                                                    </span>
                                                </div>
                                                <div className='d-flex flex-column justify-content-start fw-bold'>
                                                    <span className='fs-6 fw-bold'>
                                                        {user?.name}
                                                    </span>
                                                    <span className={`fs-7 fw-bold text-muted`}>
                                                        {user?.username}
                                                    </span>
                                                </div>
                                            </Link>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </div>

                    <div
                        ref={suggestionsElement}
                        data-kt-search-element='main'>
                        <div className='d-flex text-center fw-bold mb-4'>
                            <span className='pt-10 pb-10 text-muted'>
                                Type in the textbox to search across borrowers
                            </span>
                        </div>
                    </div>

                    <div
                        ref={emptyElement}
                        data-kt-search-element='empty'
                        className='text-center d-none'>
                        <div className='pt-10 pb-10'>
                            <KTIcon
                                iconName='search-list'
                                className='fs-4x opacity-50'
                            />
                        </div>

                        <div className='pb-15 fw-bold'>
                            <h3 className='text-gray-600 fs-5 mb-2'>No result found</h3>
                            <div className='text-muted fs-7'>
                                Please try again with a different query
                            </div>
                        </div>
                    </div>
                </div>

                <form className={`pt-1 ${menuState === 'advanced' ? '' : 'd-none'}`}>
                    <h3 className='fw-bold text-gray-900 mb-7'>Advanced Search</h3>

                    <div className='mb-5'>
                        <input
                            type='text'
                            className='form-control form-control-sm form-control-solid'
                            placeholder='Contains the word'
                            name='query'
                        />
                    </div>

                    <div className='mb-5'>
                        <div className='nav-group nav-group-fluid'>
                            <label>
                                <input
                                    type='radio'
                                    className='btn-check'
                                    name='type'
                                    value='has'
                                    defaultChecked
                                />
                                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                                    All
                                </span>
                            </label>

                            <label>
                                <input
                                    type='radio'
                                    className='btn-check'
                                    name='type'
                                    value='users'
                                />
                                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                                    Users
                                </span>
                            </label>

                            <label>
                                <input
                                    type='radio'
                                    className='btn-check'
                                    name='type'
                                    value='orders'
                                />
                                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                                    Orders
                                </span>
                            </label>

                            <label>
                                <input
                                    type='radio'
                                    className='btn-check'
                                    name='type'
                                    value='projects'
                                />
                                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                                    Projects
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className='mb-5'>
                        <input
                            type='text'
                            name='assignedto'
                            className='form-control form-control-sm form-control-solid'
                            placeholder='Assigned to'
                        />
                    </div>

                    <div className='mb-5'>
                        <input
                            type='text'
                            name='collaborators'
                            className='form-control form-control-sm form-control-solid'
                            placeholder='Collaborators'
                        />
                    </div>

                    <div className='mb-5'>
                        <div className='nav-group nav-group-fluid'>
                            <label>
                                <input
                                    type='radio'
                                    className='btn-check'
                                    name='attachment'
                                    value='has'
                                    defaultChecked
                                />
                                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary'>
                                    Has attachment
                                </span>
                            </label>

                            <label>
                                <input
                                    type='radio'
                                    className='btn-check'
                                    name='attachment'
                                    value='any'
                                />
                                <span className='btn btn-sm btn-color-muted btn-active btn-active-primary px-4'>
                                    Any
                                </span>
                            </label>
                        </div>
                    </div>

                    <div className='mb-5'>
                        <select
                            name='timezone'
                            aria-label='Select a Timezone'
                            data-control='select2'
                            data-placeholder='date_period'
                            className='form-select form-select-sm form-select-solid'>
                            <option value='next'>Within the next</option>
                            <option value='last'>Within the last</option>
                            <option value='between'>Between</option>
                            <option value='on'>On</option>
                        </select>
                    </div>

                    <div className='row mb-8'>
                        <div className='col-6'>
                            <input
                                type='number'
                                name='date_number'
                                className='form-control form-control-sm form-control-solid'
                                placeholder='Lenght'
                            />
                        </div>

                        <div className='col-6'>
                            <select
                                name='date_typer'
                                aria-label='Select a Timezone'
                                data-control='select2'
                                data-placeholder='Period'
                                className='form-select form-select-sm form-select-solid'>
                                <option value='days'>Days</option>
                                <option value='weeks'>Weeks</option>
                                <option value='months'>Months</option>
                                <option value='years'>Years</option>
                            </select>
                        </div>
                    </div>

                    <div className='d-flex justify-content-end'>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                setMenuState('main');
                            }}
                            className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'>
                            Cancel
                        </button>

                        <a
                            className='btn btn-sm fw-bolder btn-primary'
                            data-kt-search-element='advanced-options-form-search'>
                            Search
                        </a>
                    </div>
                </form>

                <form className={`pt-1 ${menuState === 'preferences' ? '' : 'd-none'}`}>
                    <h3 className='fw-bold text-gray-900 mb-7'>Search Preferences</h3>

                    <div className='pb-4 border-bottom'>
                        <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                            <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                                Projects
                            </span>

                            <input
                                className='form-check-input'
                                type='checkbox'
                                value='1'
                                defaultChecked
                            />
                        </label>
                    </div>

                    <div className='py-4 border-bottom'>
                        <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                            <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                                Targets
                            </span>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                value='1'
                                defaultChecked
                            />
                        </label>
                    </div>

                    <div className='py-4 border-bottom'>
                        <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                            <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                                Affiliate Programs
                            </span>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                value='1'
                            />
                        </label>
                    </div>

                    <div className='py-4 border-bottom'>
                        <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                            <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                                Referrals
                            </span>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                value='1'
                                defaultChecked
                            />
                        </label>
                    </div>

                    <div className='py-4 border-bottom'>
                        <label className='form-check form-switch form-switch-sm form-check-custom form-check-solid flex-stack'>
                            <span className='form-check-label text-gray-700 fs-6 fw-bold ms-0 me-2'>
                                Users
                            </span>
                            <input
                                className='form-check-input'
                                type='checkbox'
                            />
                        </label>
                    </div>

                    <div className='d-flex justify-content-end pt-7'>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                setMenuState('main');
                            }}
                            className='btn btn-sm btn-light fw-bolder btn-active-light-primary me-2'>
                            Cancel
                        </button>
                        <button className='btn btn-sm fw-bolder btn-primary'>Save Changes</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export { Search };
