const BASE_URL =
    (process.env.REACT_APP_API_ROOT_LMS || process.env.REACT_APP_API_ROOT_DEFAULT) + '/lms/';

export const urls = {
    dashboard_summary: BASE_URL + 'dashboard/summary/',
    dashboard_report: BASE_URL + 'dashboard/reports/',

    accounts: BASE_URL + 'accounts/',
    accounts_bulk_create: BASE_URL + 'accounts/bulk_create/',
    accounts_bulk_update: BASE_URL + 'accounts/bulk_update/',
    accounts_download_excel: BASE_URL + 'accounts/download_excel/',
    account_details: BASE_URL + 'accounts/:id/',
    account_activate: BASE_URL + 'accounts/:id/active/',
    account_deactivate: BASE_URL + 'accounts/:id/inactive/',

    borrower_details: BASE_URL + 'borrowers/:id/',

    line_detail: BASE_URL + 'line_detail/:id/',
    loan_data: BASE_URL + 'loan_data/:id/',

    loans: BASE_URL + 'loans/',
    
    topups: BASE_URL + 'topups/',
    topups_summary: BASE_URL + 'topups/summary/',
    topups_bulk_create: BASE_URL + 'topups/bulk_create/',
    topups_download_excel: BASE_URL + 'topups/download_excel/',
    topup_details: BASE_URL + 'topups/:id/',
    topup_tranches: BASE_URL + 'topups/:id/tranches/',
    approve_topup: BASE_URL + 'topups/:id/approve/',
    reject_topup: BASE_URL + 'topups/:id/reject/',
    archive_topup: BASE_URL + 'topups/:id/archive/',
    hold_topup: BASE_URL + 'topups/:id/hold/',
    unhold_topup: BASE_URL + 'topups/:id/unhold/',
    disburse_topup: BASE_URL + 'topups/:id/disburse/',
    schedule_topup: BASE_URL + 'topups/:id/schedule/',
    unschedule_topup: BASE_URL + 'topups/:id/unschedule/',

    topup_run_checks: BASE_URL + 'topups/run_checks/',

    approvals: BASE_URL + 'approvals/',
    approval_details: BASE_URL + 'approvals/:id/',

    payouts: BASE_URL + 'payouts/',
    payouts_add_tranche: BASE_URL + 'payouts/add_tranche/',
    payouts_unmatched: BASE_URL + 'payouts/unmatched/',
    payouts_bulk_create: BASE_URL + 'payouts/bulk_update/',
    payouts_download_excel: BASE_URL + 'payouts/download_excel/',
    payout_details: BASE_URL + 'payouts/:id/',
    payout_mark_processed: BASE_URL + 'payouts/:id/mark_processed/',
    payout_mark_failed: BASE_URL + 'payouts/:id/mark_failed/',

    tasks: BASE_URL + 'tasks/',
    task_details: BASE_URL + 'tasks/:id/',
};
